import { Icon, Paragraph, Popover } from '@loveholidays/design-system';
import { useTranslation, Translation } from '@loveholidays/phrasebook';
import React from 'react';

import { usePriceQuoteUnit } from './usePriceQuoteUnit';
import { Pricing } from '@AuroraTypes';
import { BasketSummaryDetail } from '@Components/Checkout/Basket/BasketSummaryDetail/BasketSummaryDetail';
import { useAppContext } from '@Contexts/contexts';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

export type TotalPricePopoverProps = {
  pricing: Pick<Pricing, 'displayedPricingItems' | 'totalPaidAtHotel'>;
  localCurrency: string | undefined;
};

export const TotalPricePopover: React.FC<TotalPricePopoverProps> = ({ pricing, localCurrency }) => {
  const { t } = useTranslation();
  const { priceQuoteUnit } = usePriceQuoteUnit();
  const { site } = useAppContext();

  const showTotalPriceTooltip = useFeatureFlag('ShowTotalPriceTooltip');

  if (!showTotalPriceTooltip) {
    return null;
  }

  return (
    <Popover
      content={
        <div
          sx={{
            padding: 'xs',
          }}
        >
          <Paragraph variant="extrasmall">{t('sellingTotalPricePopover.dynamicPricing')}</Paragraph>
          {pricing.totalPaidAtHotel > 0 && (
            <div
              sx={{
                paddingY: 'xs',
              }}
            >
              {pricing.displayedPricingItems.map((pricingItem) => {
                return (
                  <BasketSummaryDetail
                    key={`BasketSummaryDetail-${pricingItem.label}`}
                    {...pricingItem}
                    loading={false}
                    priceQuoteUnit={priceQuoteUnit}
                    allowZeroPrice
                  />
                );
              })}
            </div>
          )}

          {pricing.totalPaidAtHotel > 0 && (
            <Paragraph
              variant="extrasmall"
              sx={{
                color: 'textDimmedheavy',
              }}
            >
              <Translation
                translationKey="sellingTotalPricePopover.taxesExplainer"
                params={{
                  localCurrency:
                    localCurrency || t('sellingTotalPricePopover.localCurrencyFallback'),
                  targetCurrency: site.currency,
                }}
                components={[(text) => <span>{localCurrency != site.currency && text}</span>]}
              />
            </Paragraph>
          )}
        </div>
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4xs',
      }}
    >
      <Icon
        size="20"
        name="Markers/Tooltip"
      />
    </Popover>
  );
};
